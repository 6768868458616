import {type RefObject} from 'react';
import {EMPTY, isObservable, map, type Observable, type Subject} from 'rxjs';
import {
  assign,
  createMachine,
  send,
  type Actions,
  type DoneInvokeEvent,
  type InvokeCreator,
} from 'xstate';
import {isNamespaced} from '../../helpers';
import {isJSONObject} from '../../types';
import {
  isApiInstruction,
  type ApiInstruction,
  type FetchInstructionsFn,
  type Instruction,
} from './types';

/**
 * Create a state machine which manages the retrieval of new instructions.
 * Retrieved instructions are passed into the given `Subject` when returned by
 * the given `getInstructions` function. `getInstructions` is invoked when the
 * state machine enters the `PENDING` state.
 */
export const createInstructionFetchMachine = (
  options: InstructionBaseContext
): typeof showInstructionsMachine => {
  return showInstructionsMachine.withContext({
    getInstructions: options.getInstructions,
    subject: options.subject,
  });
};

type TransitionEvent<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>
> = Data extends Record<string, never> ? {type: Kind} : {type: Kind} & Data;

/** Type alias for function to used to retrieve instructions */
type ShowFetchInstructionsFn = (
  since: Parameters<FetchInstructionsFn>[1]
) => ReturnType<FetchInstructionsFn>;

interface InstructionBaseContext {
  /** Ref for function used when retrieving instructions */
  getInstructions: RefObject<ShowFetchInstructionsFn>;
  /** Subject into which newly retrieved instructions are pushed */
  subject: Subject<Instruction[] | Error>;
}

interface InstructionTypestateContext {
  observable?: Observable<ApiInstruction>;
  sinceInstructionId?: string;
  error?: Error;
  lastInstructions?: ApiInstruction[];
}

type InstructionContext = InstructionBaseContext & InstructionTypestateContext;

type Action =
  | TransitionEvent<'FETCH'>
  | TransitionEvent<'AWAIT_MORE'>
  | TransitionEvent<'RECEIVE', {instruction: ApiInstruction}>
  | TransitionEvent<'REQUEST_ERROR', {error: Error}>
  | TransitionEvent<'REQUEST_COMPLETE', {instructions: ApiInstruction[]}>
  | TransitionEvent<'SUBSCRIBE', {observable: Observable<ApiInstruction>}>;

interface TS<
  Value extends string,
  Context extends InstructionTypestateContext = Record<string, never>
> {
  value: Value;
  context: InstructionBaseContext & Context;
}

type InstructionTypestate =
  | TS<'WAITING', Pick<InstructionTypestateContext, 'sinceInstructionId'>>
  | TS<'PENDING'>
  | TS<'LISTENING', Required<Pick<InstructionTypestateContext, 'observable'>>>
  | TS<'ERROR', Required<Pick<InstructionTypestateContext, 'error'>>>
  | TS<'DONE', Required<Pick<InstructionTypestateContext, 'lastInstructions'>>>;

type FetchInstructionsResponse = Awaited<ReturnType<FetchInstructionsFn>>;

/** Type alias for xstate event triggered when `pendingInvoke` rejects */
type OnError = DoneInvokeEvent<unknown>;

/** Type alias for xstate event triggered when `pendingInvoke` resolves */
type OnResolve = DoneInvokeEvent<FetchInstructionsResponse>;

/** Promise creator called when transitioning into the `PENDING` state */
const pendingInvoke: InvokeCreator<
  InstructionContext,
  Action,
  FetchInstructionsResponse
> = (context) => {
  const getInstructions = context.getInstructions.current;
  if (typeof getInstructions === 'function') {
    return getInstructions(context.sinceInstructionId);
  } else {
    return Promise.resolve({});
  }
};

/** Actions to perform when `pendingInvoke` resolves */
const onDoneActions: Actions<InstructionContext, OnResolve> = [
  send((_, event: OnResolve): Action => {
    const result = event.data;
    if (isObservable(result)) {
      return {type: 'SUBSCRIBE', observable: result};
    } else if (
      typeof result.data?.showById !== 'undefined' &&
      result.data?.showById !== null
    ) {
      return {
        type: 'REQUEST_COMPLETE',
        instructions: result.data.showById.showInstructions,
      };
    } else if (typeof result.error !== 'undefined') {
      return {type: 'REQUEST_ERROR', error: result.error};
    } else {
      return {type: 'REQUEST_COMPLETE', instructions: []};
    }
  }),
];

/** Actions to perform when `pendingInvoke` rejects */
const onErrorActions: Actions<InstructionContext, OnError> = [
  send((context, event): Action => {
    const reason: unknown = event.data;
    return {
      type: 'REQUEST_ERROR',
      error: reason instanceof Error ? reason : new Error(),
    };
  }),
];

const showInstructionsMachine = createMachine<
  InstructionContext,
  Action,
  InstructionTypestate
>(
  {
    id: 'instructions',
    initial: 'WAITING',
    states: {
      WAITING: {
        on: {
          FETCH: {
            target: 'PENDING',
          },
        },
      },
      PENDING: {
        invoke: {
          id: 'fetch',
          src: pendingInvoke,
          onDone: {actions: onDoneActions},
          onError: {actions: onErrorActions},
        },
        on: {
          REQUEST_ERROR: {
            actions: assign({
              error: (_context, event) => event.error,
            }),
            target: 'ERROR',
          },
          REQUEST_COMPLETE: {
            actions: assign({
              lastInstructions: (_context, event) => {
                return event.instructions.filter(isApiInstruction);
              },
              sinceInstructionId: (context, event) => {
                if (event.instructions.length > 0) {
                  return event.instructions.slice(-1)[0].id;
                } else {
                  return context.sinceInstructionId;
                }
              },
            }),
            target: 'DONE',
          },
          SUBSCRIBE: {
            actions: assign({
              observable: (_context, event) => event.observable,
            }),
            target: 'LISTENING',
          },
        },
      },
      LISTENING: {
        invoke: {
          src: (context, event) =>
            event.type === 'SUBSCRIBE'
              ? event.observable.pipe(
                  map((instruction): Action => ({type: 'RECEIVE', instruction}))
                )
              : EMPTY,
          // Go to `WAITING` when the observable completes so `getInstructions`
          // is triggered again quickly. Going to `DONE` waits 5 seconds before
          // re-fetching.
          onDone: {target: 'WAITING'},
          onError: {target: 'ERROR'},
        },
        on: {
          RECEIVE: {
            actions: [
              assign({
                lastInstructions: (context, event) => {
                  return isApiInstruction(event.instruction)
                    ? [event.instruction]
                    : [];
                },
                sinceInstructionId: (context, event) => event.instruction.id,
              }),
              (context, event) => {
                context.subject.next([extractInstruction(event.instruction)]);
              },
            ],
          },
        },
      },
      ERROR: {
        entry: (context) => {
          if (context.error instanceof Error) {
            context.subject.next(context.error);
          }
        },
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
      DONE: {
        entry: ['pushInstructions'],
        on: {
          AWAIT_MORE: {
            target: 'WAITING',
          },
        },
        after: {
          5000: {
            actions: send({type: 'AWAIT_MORE'}),
          },
        },
      },
    },
  },
  {
    actions: {
      pushInstructions: (context) => {
        if (Array.isArray(context.lastInstructions)) {
          context.subject.next(
            context.lastInstructions.map(extractInstruction)
          );
        }
      },
    },
  }
);

/**
 * Creates an appropriate `Instruction` object from the `ApiInstruction` input,
 * ensuring `meta` is a `JSONObject` and `type` is namespaced.
 */
function extractInstruction(signal: ApiInstruction): Instruction {
  const instruction: Instruction = {
    type: isNamespaced(signal.kind) ? signal.kind : `unknown:${signal.kind}`,
    meta: isJSONObject(signal.meta) ? signal.meta : {},
  };
  return instruction;
}
