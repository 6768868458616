import {
  Type,
  type Static,
  type TObject,
  type TSchema,
  type TUnion,
} from '@sinclair/typebox';
import type {AnalyticsInstructionMask, ComponentAdmin} from '../types';
import {
  AnimationInstructions,
  animationStates,
  animationUi,
} from './animation-helpers';
import {styleAttr, styleAttrUi} from './style-helpers';
import {type DeriveInstructionType} from './typebox-helpers';

/** Strongly typed extension of `ComponentAdmin` using TypeBox schemas */
export interface TypedComponentAdmin<
  Schema extends TObject,
  Instructions extends TUnion,
  DefaultFieldData extends Static<Schema> = Static<Schema>
> extends Omit<
    ComponentAdmin<Instructions>,
    'defaultFieldData' | 'instructions' | 'schema' | 'uiSchema'
  > {
  defaultFieldData: DefaultFieldData;
  instructions: Instructions;
  schema: Schema;
  uiSchema: Exclude<ComponentAdmin<Instructions>['uiSchema'], undefined>;
}

/** Extract the type of module properties from `TypedComponentAdmin` */
export type SchemaTypeHelper<CA> = CA extends TypedComponentAdmin<
  infer SettingsSchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer InstructionsSchema
>
  ? Static<SettingsSchema>
  : never;

/** Extract the type of module instructions from `TypedComponentAdmin` */
export type SchemaInstructionsHelper<CA> = CA extends TypedComponentAdmin<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer SettingsSchema,
  infer InstructionsSchema
>
  ? DeriveInstructionType<InstructionsSchema>
  : never;

/**
 * Type Alias to manipulate module settings, adding `Key` with `Setting` to the
 * existing `Schema`.
 */
type AddSettingsProperty<
  Schema,
  Key extends string,
  Setting extends TSchema
> = Schema extends TObject<infer Properties>
  ? TObject<Properties & Record<Key, Setting>>
  : TObject<Record<Key, Setting>>;

/**
 * Type Alias to maniuplate instructions adding `ToAdd` to the existing
 * `Instructions` schema.
 */
type CombineInstructions<
  Instructions,
  ToAdd extends TUnion
> = Instructions extends TUnion<infer Existing>
  ? TUnion<[...Existing, ...ToAdd['anyOf']]>
  : ToAdd;

class ComponentAdminBuilder<
  Schema extends TObject,
  Instructions extends TUnion,
  DefaultFieldData extends Static<Schema> = Static<Schema>
> {
  #analyticsMask?: AnalyticsInstructionMask<Instructions>;
  #details: Omit<
    TypedComponentAdmin<Schema, Instructions, DefaultFieldData>,
    'defaultFieldData'
  >;
  #defaultFieldData: DefaultFieldData;
  #instructions: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData
  >['instructions'];
  #schema: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData
  >['schema'];
  #uiSchema: TypedComponentAdmin<
    Schema,
    Instructions,
    DefaultFieldData
  >['uiSchema'];

  constructor(
    initial: TypedComponentAdmin<Schema, Instructions, DefaultFieldData>
  ) {
    this.#analyticsMask = initial.analyticsInstructionMask;
    this.#details = initial;
    this.#defaultFieldData = initial.defaultFieldData;
    this.#instructions = initial.instructions;
    this.#schema = initial.schema;
    this.#uiSchema = initial.uiSchema;
  }

  /**
   * Create the strongly typed component definition with any manipulations from
   * builder methods applied.
   */
  build(): TypedComponentAdmin<Schema, Instructions, DefaultFieldData> {
    return {
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      instructions: this.#instructions,
      schema: this.#schema,
      uiSchema: this.#uiSchema,
    };
  }

  /**
   * Applies the given `mask` to the eventual `ComponentAdmin` definition.
   *
   * **WARNING** If called multiple times only the _latest_ mask will be
   * included in the definition.
   */
  withAnalyticsInstructionMask(
    mask: AnalyticsInstructionMask<Instructions>
  ): this {
    this.#analyticsMask = mask;
    return this;
  }

  /**
   * Adds properties and instructions necessary for animation functionality to
   * the `ComponentAdmin` definition.
   */
  withAnimationStates(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
    CombineInstructions<Instructions, typeof AnimationInstructions>,
    DefaultFieldData
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'animationStates', typeof animationStates>,
      CombineInstructions<Instructions, typeof AnimationInstructions>,
      DefaultFieldData
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error shape of instructions can't be spread into union in a
      // type safe way
      instructions:
        typeof this.#instructions === 'undefined'
          ? AnimationInstructions
          : Type.Union([
              ...this.#instructions.anyOf,
              ...AnimationInstructions.anyOf,
            ]),
      // @ts-expect-error there's a type mismatch because spreading `properties`
      // doesn't convey the semantics of the Typebox schema definition
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, animationStates},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...animationUi,
      },
    });
    return instance;
  }

  /**
   * Adds properties necessary for custom styling functionality to the
   * `ComponentAdmin` definition.
   */
  withStyles(): ComponentAdminBuilder<
    AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
    Instructions,
    DefaultFieldData
  > {
    const instance = new ComponentAdminBuilder<
      AddSettingsProperty<Schema, 'styleAttr', typeof styleAttr>,
      Instructions,
      DefaultFieldData
    >({
      ...this.#details,
      analyticsInstructionMask: this.#analyticsMask,
      defaultFieldData: this.#defaultFieldData,
      // @ts-expect-error there's a type mismatch because spreading `properties`
      // doesn't convey the semantics of the Typebox schema definition
      schema: Object.assign({}, this.#schema, {
        properties: {...this.#schema.properties, styleAttr},
      }),
      uiSchema: {
        ...this.#uiSchema,
        ...styleAttrUi,
      },
    });
    return instance;
  }
}

/**
 * Create a builder for a `ComponentAdmin` instance with inferred instruction
 * types. The builder enables composing a `ComponentAdmin` instance
 * semi-fluently.
 */
export function createComponentAdmin<
  Schema extends TObject,
  Instructions extends TUnion,
  DefaultFieldData extends Static<Schema> = Static<Schema>
>(
  props: TypedComponentAdmin<Schema, Instructions, DefaultFieldData>
): Omit<
  ComponentAdminBuilder<Schema, Instructions, DefaultFieldData>,
  'build'
> {
  return new ComponentAdminBuilder(props);
}
