import {Observable} from 'rxjs';
import type {JSONObject, JSONValue} from '../../types';

export function isApiInstruction(
  action: ApiInstruction
): action is ApiInstruction {
  return (
    typeof action.meta === 'object' &&
    !Array.isArray(action.meta) &&
    action.meta !== null
  );
}

export interface ApiInstruction {
  id: string;
  kind: string;
  meta: JSONValue;
}

type FetchInstructionsResponse =
  | GetShowInstructionsResult
  | Observable<ApiInstruction>;

/** Type alias for retrieving show instructions for a given `showId` */
export type FetchInstructionsFn = (
  showId: string,
  sinceInstructionId?: string
) => Promise<FetchInstructionsResponse>;

type GetShowInstructionsResult = {
  data?: GetShowInstructions;
  error?: Error;
};

export interface Instruction {
  type: string;
  meta: JSONObject;
}

interface ShowById {
  id: string;
  /**
   * The sequence of actions and markers which have occurred for this show.
   */
  showInstructions: ApiInstruction[];
}

interface GetShowInstructions {
  showById: ShowById | null;
}

/** Type alias for function to used to retrieve instructions */
export type ShowFetchInstructionsFn = (
  since: Parameters<FetchInstructionsFn>[1]
) => ReturnType<FetchInstructionsFn>;
